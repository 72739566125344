body, html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f1f2f6;
  color: #3c4858;
}

a {
  color: #60a3bc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header, .footer {
  background-color: #0a3d62;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
}
