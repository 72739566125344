/* App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #F8F8F8;
  color: #000080;
}

a {
  color: #5D8AA8;
  text-decoration: none; /* Removes underline from links */
}

a:hover {
  text-decoration: underline; /* Adds underline on hover for links */
}

.header, .footer {
  background-color: #000080;
  color: #FFFFFF;
  padding: 20px;
  text-align: center;
}

.main {
  padding: 20px;
}
.button {
  background-color: #60a3bc;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.button:hover {
  background-color: #3c4858;
}
@media screen and (max-width: 600px) {
  .header nav ul li {
    float: none; /* Stack the links vertically on small screens */
  }
}
/* Header and Navigation Styles using Flexbox */
.header {
  background-color: #0a3d62; /* Navy blue background */
  color: white;
  padding: 10px 20px; /* Adjust padding as needed */
}

.header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex; /* Use flexbox */
  justify-content: space-around; /* Evenly distribute the space around items */
  align-items: center; /* Align items vertically */
}

.header nav ul li {
  /* Removed the float property, no longer needed with flexbox */
}

.header nav ul li a {
  display: block; /* Make the entire area clickable */
  color: white;
  text-align: center;
  padding: 14px 16px; /* Padding inside the navigation links */
  text-decoration: none;
}


.header nav ul li a {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  display: inline-block; /* Allows the use of width and height on 'a' tags */
  margin: 0 15px; /* Adjust spacing between navigation links as needed */
  text-decoration: none;
  color: white; /* Text color */
  padding: 10px 0; /* Padding to increase the clickable area */
}

.header nav ul li a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%; /* Starts with no width for non-active links */
  height: 3px; /* Make the line a bit thicker to increase visibility */
  background-color: red; /* Change to red */
  transition: width 0.5s ease, background-color 0.5s ease;
}

.header nav ul li a:hover::after, .header nav ul li a.active::after {
  width: 100%; /* Full width on hover or if the link is active */
  background-color: red;
}
.header nav ul li a:hover, .header nav ul li a.active:hover {
  background-color: transparent; /* Ensures no background color on hover */
  color: white; /* Adjust if you want to change the text color on hover */
}


